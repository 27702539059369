<template>
  <div class="user">
    <!-- 小盒子1 -->
    <div class="utop">
      <div class="ucet">
        <div class="userTop">
          <!--  小盒子1 左侧-->
          <div class="userTopLeft">
            <img class="userImg" :src="userInfo.img" alt="" />
            <div class="w-b-100">
              <div class="nickName">{{ userInfo.nickName }}</div>
              <div class="flex-c">
                <el-dropdown class="dropBox" trigger="click">
                  <div class="title">
                    <div class="elp">
                      {{ search.className }}
                    </div>
                    <i class="iconfont icon-a-Group1895"></i>
                  </div>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-for="(item, index) in classlist" :key="index">
                      <div @click="getDetail(item)">
                        {{ item.name }}
                      </div>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <!--   <el-select v-model="search.class" placeholder="请选择" @change="getDetail">
                  <el-option
                    v-for="(item, index) in classlist"
                    :key="index"
                    :label="item.name"
                    :value="item.coursesId"
                  >
                  </el-option>
                </el-select> -->
                <!-- <i class="iconfont icon-a-Group1895" /> -->
              </div>
            </div>
          </div>
          <!-- <p>张凯凯</p> -->
          <div class="userTopRight">
            <div class="userTopCent">
              <div class="title">录播学习率</div>
              <div class="text">
                {{ classIdDetails.videoStudy }}<span class="icon">%</span>
              </div>
            </div>
            <div class="userTopCents"></div>
            <div class="userTopCent">
              <div class="title">直播到课率</div>
              <div class="text">
                {{ classIdDetails.liveDKStudy }}<span class="icon">%</span>
              </div>
            </div>
            <div class="userTopCents"></div>
            <div class="userTopCent">
              <div class="title">7天直播学习率</div>
              <div class="text">
                {{ classIdDetails.liveSevenStudy }}<span class="icon">%</span>
              </div>
            </div>
            <!-- <div class="userTopCents"></div>
            <div class="userTopCent">
              <div class="title">考场到考率</div>
              <div class="text">
                {{ classIdDetails.roomDkStudy }}<span class="icon">%</span>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="box-content">
        <!-- 小盒子2 -->
        <div class="userCent">
          <div class="c_nav">
            <el-tabs v-model="tabId" @tab-click="tabChange">
              <el-tab-pane v-for="(item, index) in list" :key="index" :label="item.title" :name="item.id">
              </el-tab-pane>
            </el-tabs>
          </div>
          <!-- 右侧盒子 -->
          <router-view></router-view>
        </div>
      </div>
      <div class="youlove">
        <timetable></timetable>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from "@/api/bus";
import Vue from "vue";
import { getInfo } from "@/api/cookies";
import { getUserCourseStatistics } from "@/api/user";
import Timetable from "@/views/mine/my/TimetableCcolleges.vue";
import {
  selectPcStuClassIdDetails,
  getNoPastMClassCourseList,
  getPCClassRate,
} from "@/api/mykecheng";
export default {
  components: { Timetable },
  data() {
    return {
      userInfo: null,
      userId: "",
      list: [
        {
          id: "1",
          title: "我的课程",
          url: "/seventhPage/mycurriculum",
          icon: "icon-wodekecheng",
          img: require("@/assets/img/learn/Group1.png"),
        },
        {
          id: "4",
          title: "学习资料",
          url: "/seventhPage/mydata",
          icon: "icon-a-zu320",
          img: require("@/assets/img/learn/Group2.png"),
        },
        {
          id: "3",
          title: "我的题库",
          url: "/seventhPage/myexamination",
          icon: "icon-a-zu319",
          img: require("@/assets/img/learn/Group3.png"),
        },
        {
          id: "5",
          title: "我的考场",
          url: "/seventhPage/myRoom",
          icon: "icon-a-zu321",
          img: require("@/assets/img/learn/Group4.png"),
        },
        {
          id: "7",
          title: "我的作业",
          url: "/seventhPage/myOperation",
          icon: "icon-a-zu321",
          img: require("@/assets/img/learn/Group4.png"),
        },
        {
          id: "2",
          title: "最近学习",
          url: "/seventhPage/study",
          icon: "icon-xuexijilu",
          img: require("@/assets/img/learn/Group5.png"),
        },
        // {
        //   id: "6",
        //   title: "学习记录",
        //   url: "/seventhPage/learningRecord",
        //   icon: "icon-xuexijilu",
        //   img: require("@/assets/img/learn/Group5.png"),
        // },
      ],
      tabId: "1",
      chong: {},
      classlist: [],
      search: {},
      classIdDetails: {}, //学习率
    };
  },
  watch: {
    //使用这个可以监听data中指定数据的变化,然后触发watch中对应的function的处理
    $route: function (newVal, oldVal) {
      if (oldVal.path == "/login" || newVal.path == "/login") {
        this.userId = localStorage.getItem("userId");
      }
    },
  },
  async created() {
    this.userInfo = getInfo();
    if (this.userInfo) {
      await getNoPastMClassCourseList(
        this.userInfo.id,
        this.userInfo.tenantId
      ).then((res) => {
        this.classlist = res;
        if (this.classlist && this.classlist.length > 0) {
          this.getDetail(this.classlist[0]);
        }
      });
    }
    for (let item of this.list) {
      if (this.$route.path == item.url) {
        this.tabId = item.id;
        break;
      }
    }
    this.userId = localStorage.getItem("userId");
    if (!this.userId) {
      Vue.prototype.goLoginView("mustBack");
      return;
    }

    // 个人中心 顶部数据
    getUserCourseStatistics(this.userId).then((res) => {
      this.chong = res.msg;
    });

    //滚动条监控  答题卡 滚动到顶部悬浮
    $(document).scroll(function () {
      // 滚动到上面  隐藏
      $(window).scroll(function () {
        let top = $(window).scrollTop();
        if (top >= 290) {
          $(".userCentLeft").addClass("fixed-card");
          $(".zhicheng").removeClass("dis-none");
        } else {
          $(".userCentLeft").removeClass("fixed-card");
          $(".zhicheng").addClass("dis-none");
        }
      });
    });
  },
  methods: {
    tabChange() {
      for (let item of this.list) {
        if (item.id == this.tabId) {
          this.$router.replace(item.url);
        }
      }
    },
    getDetail(item) {
      this.search.className = item.name;
      this.search.class = item.type + "," + item.coursesId;
      /* 班型学习率 */
      let data = {
        userId: this.userInfo.id,
        classId: this.search.class,
        tenantId: this.userInfo.tenantId,
      };
      getPCClassRate(data).then((res) => {
        this.classIdDetails = res.data;
      });
      this.$forceUpdate();
    },
  },
};
</script>
<style lang="less" scoped>
/* /deep/ .el-select {
  width: 100%;
  margin-top: 20px;
  .el-input__inner {
    height: auto !important;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 14px;
    padding: 0px;
    background: none !important;
    width: 100%;
    border: none !important;
    overflow: hidden; //超出一行文字自动隐藏
    text-overflow: ellipsis; //文字隐藏后添加省略号
    white-space: nowrap; //强制不换行
  }
  .el-input__suffix {
    display: none;
  }
} */
.area-selected-trigger {
  width: 300px;
  height: 40px;
  line-height: 20px;
}

.el-input.el-input__inner {
  width: 100px;
}

.el-dropdown-menu {
  max-height: 300px !important;
  overflow-y: scroll !important;
}

/deep/ .dropBox {
  margin-bottom: 16px;
  margin-top: 28px;
  max-width: 600px;

  .title {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 14px;
    display: flex;
    align-items: center;

    .iconfont {
      font-size: 10px;
      color: #fff;
      margin-left: 8px;
      flex-shrink: 0;
    }
  }
}

// 大盒子
.user {
  width: 100%;
  height: auto;
  background: #f5f6fa;
  background-size: 100% 240px;
  background-repeat: no-repeat;
  overflow: hidden;

  .utop {
    box-sizing: border-box;
    width: 100%;
    height: 160px;

    background-image: url("~@/assets/img/homeSeventh/mineBg.png");
    background-size: 100% 100%;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    display: flex;
    align-items: center;

    .ucet {
      width: 1200px;
      margin: 0 auto;

      .userTop {
        height: 100px;
        width: 100%;
        background-color: rgba(255, 255, 255, 0);
        display: flex;
        align-items: center;

        // 左侧
        .userTopLeft {
          width: 100%;
          display: flex;
          align-items: center;

          // 头像
          .userImg {
            width: 80px;
            height: 80px;
            opacity: 1;
            border: 2px solid #ffffff;
            border-radius: 50%;
            flex-shrink: 0;
            margin-right: 16px;
          }

          //  昵称
          .nickName {
            font-size: 22px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            line-height: 22px;
          }

          .hi {
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            line-height: 14px;
            margin-top: 20px;
          }

          .iconfont {
            font-size: 10px;
            flex-shrink: 0;
            flex-shrink: 0px;
            color: #fff;
          }
        }

        // 右侧
        .userTopRight {
          width: auto;
          height: 45px;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          // 斜线
          .userTopCents {
            width: 1px;
            height: 100%;
            background: #efefef;
            transform: rotate(20deg);
            margin: 0px 30px;
          }

          // 内容
          .userTopCent {
            height: 100%;
            width: max-content;
            padding: 0px 11px;
            text-align: center;

            .title {
              font-size: 14px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #ffffff;
              line-height: 14px;
            }

            .text {
              font-size: 24px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #ffffff;
              line-height: 24px;
              margin-top: 16px;
            }
          }
        }
      }
    }
  }

  /* 标题导航栏 */
  .c_nav {
    width: 100%;
    background: #ffffff;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;

    /deep/ .el-tabs {
      .el-tabs__header {
        margin: 0px !important;
      }

      .el-tabs__item {
        padding-top: 24px;
        padding-bottom: 15px;
        height: auto !important;
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 16px;
      }

      .is-active {
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 16px;
      }

      .el-tabs__active-bar {
        width: 20px !important;
        height: 3px;
        background: #4a6af0;
        border-radius: 1px 1px 1px 1px;
        opacity: 1;
        margin-left: 22px;
      }

      .el-tabs__nav-wrap::after {
        height: 1px;
        background: #ececec;
      }
    }
  }

  // 小盒子2
  .userCent {
    width: 880px;
    height: auto;
    // margin: 0 20px 0;
    margin-bottom: 100px;
    padding: 0 20px;
    // padding: 0px 24px 24px;
    background: #fff;
    border-radius: 8px 8px 8px 8px;
    margin-right: 20px;

    // 小盒子2左侧
    .userCentLeft {

      // 单个模块盒子
      .userCentLefts {
        width: 100%;
        height: auto;
        margin-top: 27px;

        // 单个模块盒子标题
        p {
          width: 100%;
          height: 40px;
          font-size: 16px;
          text-align: center;
          line-height: 40px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #333333;
          margin-left: 3px;
        }

        // 单个模块盒子内容
        .userCentLeftsC {
          width: 100%;
          height: 36px;

          // 右侧列表内容
          .userCentLeftsCr {
            width: 100%;
            height: 36px;
            line-height: 36px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
            cursor: pointer;
            padding-left: 58px;
            border-left: 3px solid #fff;
          }
        }

        // 单个模块盒子内容点击后样式
        .on {
          background: #fcefed;

          .userCentLeftsCr {
            color: #ff4027;
            border-left: 3px solid #ff4027;
          }
        }
      }
    }
  }
}

.zhicheng {
  width: 180px;
  height: 100%;
  margin-right: 20px;
  flex-shrink: 0;
}

.content {
  width: 1200px;
  margin: 20px calc(50% - 600px);
  display: flex;
  justify-content: space-between;

  .box-content {
    width: 1020px;
  }

  .youlove {
    width: 360px;
    flex-shrink: 0;
  }
}
</style>
